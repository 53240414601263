import React from "react";
import "../assets/scss/Cards.scss";
import "../assets/scss/Variables.scss";
import CardItem from "./CardItem";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const transition = { duration: 1.5, ease: [0.43, 0.13, 0.23, 0.96] };

function Cards() {
  return (
    <section className="cards">
      {/* <div className="card_title">Rocking Projects</div> */}
      <div className="cards__container">
        <div className="cards__wrapper">
          <div className="cards__items">
            <div className="cards__item">
              <Link className="cards__item__link panel" to="/tira" id="linkAnchor">
                <motion.figure
                  className="cards__item__pic-wrap"
                  // transition={transition}
                  // whileHover={{ transform: "translateY(-5rem)" }}
                >
                  <motion.video
                    className="cards__item__img"
                    alt="Tira Beauty"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/tira_cover.mp4"
                    transition={transition}
                    whileHover={{ paddingBottom: "5rem" }}
                  />
                </motion.figure>
                <motion.div
                  exit={{ opacity: 0 }}
                  transition={transition}
                  className="cards__item__info"
                >
                  <div className="title">Tira Beauty</div>
                  <div className="text">
                  Making a playful introduction
                  </div>
                </motion.div>
              </Link>
            </div>
            <CardItem
              src="/images/wn_cover.jpg"
              title="We:Neighborhood"
              alt="We:Neighborhood"
              text="Reimagining the public restroom"
              path="/we-neighborhood"
            />
            <CardItem
              src="/images/pause_cover.jpg"
              title="PAUSE RESTROOMS"
              alt="Pause Restrooms"
              text="Reimagining the public restroom"
              path="/pause-restrooms"
            />
            <CardItem
              src="/images/self-matters_cover.jpg"
              title="SELF MATTERS"
              alt="Self Matters"
              text="Bringing self care and wellness into focus"
              path="/selfmatters"
            />
            <CardItem
              src="/images/friendly-laundry_cover.jpg"
              title="Friendly Laundry"
              alt="Friendly Laundry"
              text="Washing out the mundane with a purpose"
              path="/friendly-laundry"
            />
            <CardItem
              src="/images/furry-essentials_cover.jpg"
              title="Furry Essentials"
              alt="Furry Essentials"
              text="Pampering is a must for our furry favourites"
              path="/furry-essentials"
            />
            <CardItem
              src="/images/tb_hd_02.jpg"
              title="Communiti Craft Beer"
              alt="Communiti Craft Beer"
              text="Brewing a mix of modernity and heritage"
              path="/truly-belgian"
            />
            <CardItem
              src="/images/oye-kake_cover.jpg"
              title="Oye Kake"
              alt="Oye Kake"
              text="Adding a zing of colour to amp up the flavour of culture"
              path="/oye-kake"
            />
            <CardItem
              src="/images/ank_cover.jpg"
              title="A New Knot"
              alt="A New Knot"
              text="Binding momentous occasions with elegance"
              path="/a-new-knot"
            />
            <div className="cards__item">
              <Link className="cards__item__link panel" to="/awakeful" id="linkAnchor">
                <motion.figure
                  className="cards__item__pic-wrap"
                  // transition={transition}
                  // whileHover={{ transform: "translateY(-5rem)" }}
                >
                  <motion.video
                    className="cards__item__img"
                    alt="Awakeful"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/awakeful_hd_01.mp4"
                    transition={transition}
                    whileHover={{ paddingBottom: "5rem" }}
                  />
                </motion.figure>
                <motion.div
                  exit={{ opacity: 0 }}
                  transition={transition}
                  className="cards__item__info"
                >
                  <div className="title">Awakeful</div>
                  <div className="text">
                    Opening your eyes to an exciting world
                  </div>
                </motion.div>
              </Link>
            </div>
            <CardItem
              src="/images/aat_cover.jpg"
              title="All About Them"
              alt="All About Them"
              text="Bringing four-leggeds the attention they deserve"
              path="/aat"
            />
            
            <CardItem
              src="/images/mumkin_cover.jpg"
              title="Mumkin"
              alt="Mumkin"
              text="Instilling hope one smile at a time"
              path="/mumkin"
            />
            
            <CardItem
              src="/images/kinstrukto_cover.jpg"
              title="Kinstrukto"
              alt="Kinstrukto"
              text="Time to unblock creativity"
              path="/kinstrukto"
            />
            <CardItem
              src="/images/atl_btl_ftl_cover.jpg"
              title="ATL BTL FTL"
              alt="ATL BTL FTL"
              text="Breaking barriers one chant at a time"
              path="/atl-btl-ftl"
            />
            <CardItem
              src="/images/quarantine_cover.jpg"
              title="QUARANTINE SOUNDTRACKS"
              alt="Quarantine Soundtracks"
              text="Moving tunes for when the world stood still"
              path="/quarantine-soundtracks"
            />
            <CardItem
              src="/images/fake_song_cover_01.jpg"
              title="Fake Song"
              alt="Fake Song"
              text="Faking a world that should in fact be real"
              path="/fake-song"
            />
            <CardItem
              src="/images/scrolling-all_day_cover.jpg"
              title="Scrolling All Day"
              alt="Scrolling All Day"
              text="Can’t stop scrolling, won’t stop scrolling"
              path="/scrolling-all-day"
            />
            <CardItem
              src="/images/launda-befikar_cover.jpg"
              title="LAUNDA BEFIKAR"
              alt="Launda Befikar"
              text="Redefining the public restroom"
              path="/launda-befikar"
            />
            <CardItem
              src="/images/khota-sikka_cover.jpg"
              title="Khota Sikka"
              alt="Khota Sikka"
              text="Redefining the public restroom"
              path="/khota-sikka"
            />
            <div className="cards__item">
              <Link className="cards__item__link panel" to="/studio-ad+ad">
                <motion.figure
                  className="cards__item__pic-wrap"
                  // transition={transition}
                  // whileHover={{ transform: "translateY(-5rem)" }}
                >
                  <motion.video
                    className="cards__item__img"
                    alt="STUDIO AD+AD"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/studio_adad_am.mp4"
                    transition={transition}
                    whileHover={{ paddingBottom: "5rem" }}
                  />
                </motion.figure>
                <motion.div
                  exit={{ opacity: 0 }}
                  transition={transition}
                  className="cards__item__info"
                >
                  <div className="title">STUDIO AD+AD</div>
                  <div className="text">
                    Converging two perspectives into one
                  </div>
                </motion.div>
              </Link>
            </div>
            <div className="cards__item">
              <Link className="cards__item__link panel" to="/topical">
                <motion.figure
                  className="cards__item__pic-wrap"
                  // transition={transition}
                  // whileHover={{ transform: "translateY(-5rem)" }}
                >
                  <motion.video
                    className="cards__item__img"
                    alt="Topical"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/topical_cover_am.mp4"
                    transition={transition}
                    whileHover={{ paddingBottom: "5rem" }}
                  />
                </motion.figure>
                <motion.div
                  exit={{ opacity: 0 }}
                  transition={transition}
                  className="cards__item__info"
                >
                  <div className="title">Topical</div>
                  <div className="text">
                    Evolving constantly to keep up with the times
                  </div>
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cards;
