import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { motion, useViewportScroll, useTransform } from "framer-motion";

import "../assets/scss/App.scss";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import "../assets/scss/Cards.scss";
import "../assets/scss/Variables.scss";
import CardItem from "../components/CardItem";
// import { motion } from "framer-motion";

import ScrollToTop from "../components/ScrollToTop";

const transition = { duration: 1 };

// const Home = ({ imageDetails }) => {
//   const { scrollYProgress } = useViewportScroll();
//   const scale = useTransform(scrollYProgress, [0, 1], [1, 2]);


function Home() {
  return (
    <>
      <ScrollToTop />
      <HeroSection />
      <InfoSection />
      <section className="cards">
        <div className="card_title">Rocking Projects</div>
        <div className="cards__container">
          <div className="cards__wrapper">
            <motion.div 
            
            className="cards__items" style={{ paddingTop: "2rem" }}>
              <div className="cards__item">
              <Link className="cards__item__link panel" to="/tira" id="linkAnchor">
                <motion.figure
                  className="cards__item__pic-wrap"
                  // transition={transition}
                  // whileHover={{ transform: "translateY(-5rem)" }}
                >
                  <motion.video
                    className="cards__item__img"
                    alt="Tira Beauty"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/tira_cover.mp4"
                    transition={transition}
                    whileHover={{ paddingBottom: "5rem" }}
                  />
                </motion.figure>
                <motion.div
                  exit={{ opacity: 0 }}
                  transition={transition}
                  className="cards__item__info"
                >
                  <div className="title">Tira Beauty</div>
                  <div className="text">
                  Making a playful introduction
                  </div>
                </motion.div>
              </Link>
            </div>
              <CardItem
                src="/images/wn_cover.jpg"
                title="We:Neighborhood"
                alt="WeNeighborhood"
                text="Designing the brewery that runs on the sun"
                path="/we-neighborhood"
              />
              <CardItem
                src="/images/pause_cover.jpg"
                title="PAUSE RESTROOMS"
                alt="Pause Restrooms"
                text="Reimagining the public restroom"
                path="/pause-restrooms"
              />
              <CardItem
                src="/images/self-matters_cover.jpg"
                title="SELF MATTERS"
                alt="Self Matters"
                text="Bringing self care and wellness into focus"
                path="/selfmatters"
              />
              <CardItem
                src="/images/friendly-laundry_cover.jpg"
                title="Friendly Laundry"
                alt="Friendly Laundry"
                text="Washing out the mundane with a purpose"
                path="/friendly-laundry"
              />
              <CardItem
                src="/images/furry-essentials_cover.jpg"
                title="Furry Essentials"
                alt="Furry Essentials"
                text="Pampering is a must for our furry favourites"
                path="/furry-essentials"
              />
              <CardItem
                src="/images/tb_hd_02.jpg"
                title="Communiti Craft Beer"
                alt="Communiti Craft Beer"
                text="Brewing a mix of modernity and heritage"
                path="/truly-belgian"
              />
              <CardItem
                src="/images/oye-kake_cover.jpg"
                title="Oye Kake"
                alt="Oye Kake"
                text="Adding a zing of colour to amp up the flavour of culture"
                path="/oye-kake"
              />
              {/* <CardItem
                src="/images/ank_cover.jpg"
                title="A New Knot"
                alt="A New Knot"
                text="Binding momentous occasions with elegance"
                path="/a-new-knot"
              /> */}
              {/* <div className="cards__item">
                <Link className="cards__item__link panel" to="/awakeful">
                  <motion.figure
                    className="cards__item__pic-wrap"
                    // transition={transition}
                    // whileHover={{ transform: "translateY(-5rem)" }}
                  >
                    <motion.video
                      className="cards__item__img"
                      alt="Awakeful"
                      autoPlay
                      loop
                      muted
                      playsInline
                      src="/videos/awakeful_hd_01.mp4"
                      transition={transition}
                      whileHover={{ paddingBottom: "5rem" }}
                    />
                  </motion.figure>
                  <motion.div
                    exit={{ opacity: 0 }}
                    transition={transition}
                    className="cards__item__info"
                  >
                    <div className="title">Awakeful</div>
                    <div className="text">
                      Opening your eyes to an exciting world
                    </div>
                  </motion.div>
                </Link>
              </div> */}
            </motion.div>
          </div>
        </div>

        <HashLink to="/work#linkAnchor" className="see_more">
          See More Work
        </HashLink>
      </section>
    </>
  );
};

export default Home;
