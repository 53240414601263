import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import gsap from "gsap";
import { Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Info from "./pages/Info";
import Mumkin from "./pages/Mumkin";
import WeNeighborhood from "./pages/WeNeighborhood";
import PauseRestrooms from "./pages/PauseRestrooms";
import Selfmatters from "./pages/Selfmatters";
import FriendlyLaundry from "./pages/FriendlyLaundry";
import OyeKake from "./pages/OyeKake";
import TrulyBelgian from "./pages/TrulyBelgian";
import KhotaSikka from "./pages/KhotaSikka";
import QuarantineSoundtracks from "./pages/QuarantineSoundtracks";
import Awakeful from "./pages/Awakeful";
import LaundaBefikar from "./pages/LaundaBefikar";
import StudioADAD from "./pages/StudioADAD";
import Kinstrukto from "./pages/Kinstrukto";
import Topical from "./pages/Topical";
import AtlBtlFtl from "./pages/AtlBtlFtl";
import AAT from "./pages/AAT";
import Tira from "./pages/Tira";
import ANewKnot from "./pages/ANewKnot";
import FurryEssentials from "./pages/FurryEssentials";
import FakeSong from "./pages/FakeSong";
import ScrollingAllDay from "./pages/ScrollingAllDay";

import ScrollToTop from "./components/ScrollToTop";

import "./assets/scss/App.scss";

export default function App() {
  gsap.registerPlugin(ScrollTrigger);

  window.addEventListener("load", function () {
    let splitWords = function (selector) {
      var elements = document.querySelectorAll(selector);

      elements.forEach(function (el) {
        el.dataset.splitText = el.textContent;
        el.innerHTML = el.textContent
          .split(/\s/)
          .map(function (word) {
            return word
              .split("-")
              .map(function (word) {
                return '<span class="word">' + word + "</span>";
              })
              .join('<span class="hyphen">-</span>');
          })
          .join('<span class="whitespace"> </span>');
      });
    };

    let splitLines = function (selector) {
      var elements = document.querySelectorAll(selector);

      splitWords(selector);

      elements.forEach(function (el) {
        var lines = getLines(el);

        var wrappedLines = "";
        lines.forEach(function (wordsArr) {
          wrappedLines += '<span class="line"><span class="words">';
          wordsArr.forEach(function (word) {
            wrappedLines += word.outerHTML;
          });
          wrappedLines += "</span></span>";
        });
        el.innerHTML = wrappedLines;
      });
    };

    let getLines = function (el) {
      var lines = [];
      var line;
      var words = el.querySelectorAll("span");
      var lastTop;
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        if (word.offsetTop !== lastTop) {
          // Don't start with whitespace
          if (!word.classList.contains("whitespace")) {
            lastTop = word.offsetTop;

            line = [];
            lines.push(line);
          }
        }
        line.push(word);
      }
      return lines;
    };

    splitLines(".reveal-text");

    let revealText = document.querySelectorAll(".reveal-text");

    let revealLines = revealText.forEach((element) => {
      const lines = element.querySelectorAll(".words");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleActions: "restart none none reset",
        },
      });
      tl.set(element, { autoAlpha: 1 });
      tl.from(lines, 1, {
        yPercent: 100,
        ease: Power3.out,
        stagger: 0.5,
        delay: 1,
        scrollTrigger: {
          trigger: ".reveal-text",
          end: "center center",
          scrub: 1,
        },
      });
    });
  });

  const imageDetails = {
    width: 524,
    height: 650,
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <main>
          <Route
            render={({ location }) => (
              
              <AnimatePresence initial={false} exitBeforeEnter>
                <Switch >
                  <Route exact path="/" component={Home} />
                  <Route exact path="/work" component={Work} />
                  <Route exact path="/info" component={Info}  />
                  <Route
                    exact
                    path="/mumkin"
                    render={() => <Mumkin imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/tira"
                    render={() => <Tira imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/we-neighborhood"
                    render={() => <WeNeighborhood imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/pause-restrooms"
                    render={() => <PauseRestrooms imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/selfmatters"
                    render={() => <Selfmatters imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/friendly-laundry"
                    render={() => <FriendlyLaundry imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/oye-kake"
                    render={() => <OyeKake imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/truly-belgian"
                    render={() => <TrulyBelgian imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/khota-sikka"
                    render={() => <KhotaSikka imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/awakeful"
                    render={() => <Awakeful imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/launda-befikar"
                    render={() => <LaundaBefikar imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/studio-ad+ad"
                    render={() => <StudioADAD imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/kinstrukto"
                    render={() => <Kinstrukto imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/topical"
                    render={() => <Topical imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/aat"
                    render={() => <AAT imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/fake-song"
                    render={() => <FakeSong imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/scrolling-all-day"
                    render={() => <ScrollingAllDay imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/a-new-knot"
                    render={() => <ANewKnot imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/quarantine-soundtracks"
                    render={() => <QuarantineSoundtracks imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/atl-btl-ftl"
                    render={() => <AtlBtlFtl imageDetails={imageDetails} />}
                  />
                  <Route
                    exact
                    path="/furry-essentials"
                    render={() => <FurryEssentials imageDetails={imageDetails} />}
                  />
                </Switch>
              </AnimatePresence>
            )}
          />
        </main>
        <Footer />
      </Router>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
