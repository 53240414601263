import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function KhotaSikka() {
  document.body.style.backgroundColor = "#fbf2d7";
  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <figure className="project__item__pic-wrap" data-category="">
              <LazyLoadImage
                className="project__item__img"
                alt="Khota Sikka"
                src="/images/khota-sikka_banner.jpg"
              />
            </figure>
          </section>

          <section className="project_summary_container">
            <div className="title">KHOTA SIKKA</div>
            <div className="tags">
              Concept &nbsp;|&nbsp; CGI &nbsp;|&nbsp; Video
            </div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              With a unique style and a mellow voice, MC Heam is an Indian
              rapper with a difference. He approached us with the task of
              creating a video for the song “Khota Sikka” (worthless coin).
              Using the name as the starting point, we created visuals that
              represent an antique Indian coin, no longer in use but of value if
              you changed your perspective.
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <iframe
                title="Khota Sikka by MC Heam"
                src="https://www.youtube.com/embed/p23bUe_wGC0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                alt="Khota Sikka by MC Heam"
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Khota Sikka"
                src="/images/khota-sikka_sq_01.jpg"
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Khota Sikka"
                src="/images/khota-sikka_sq_02.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/khota-sikka_peace.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <PreviousProject
          src="/images/fake_song_cover_01.jpg"
          title="Fake Song"
          alt="Fake Song"
          text="Moving tunes for when the world stood still"
          path="/fake-song"
        />

        <section id="next_project">
          <Link className="next_project" to="/studio-ad+ad">
            <div className="next-tab">
              <h3>Next project</h3>
              <div className="arrow-wrapper">
                <div className="next-arrow" />
              </div>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="details"
            >
              <motion.figure className="cards__item__pic-wrap">
                <motion.video
                  className="cards__item__img"
                  alt="STUDIO AD+AD"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src="/videos/studio_adad_am.mp4"
                  transition={transition}
                />
              </motion.figure>
              <motion.div className="cards__item__info">
                <div className="title">STUDIO AD+AD</div>
                <div className="text">Converging two perspectives into one</div>
              </motion.div>
            </motion.div>
          </Link>
        </section>
      </section>
    </>
  );
}
