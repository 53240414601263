import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import "../assets/scss/Project.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ScrollToTop from "../components/ScrollToTop";
import NextProject from "../components/NextProject";
import PreviousProject from "../components/PreviousProject";

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Topical() {
  document.body.style.backgroundColor = "#e7e7e7";

  return (
    <>
      {/* <ScrollToTop /> */}
      <div className="project">
        <div className="scroll">
          <section className="project_banner">
            <video
              src="/videos/topical_am_03.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </section>

          <section className="project_summary_container">
            <div className="title">NOW IS EVERYTHING</div>
            <div className="tags">Identity & Branding</div>
          </section>

          <section
            className="project_info_container"
            style={{ justifyContent: "center" }}
          >
            <div className="info">
              <span>
                Meet Topical - a digital advertising agency, that helps brands
                stay relevant by creating branded content and experiences
                through context and topicality.
              </span>
              <br />
              <br />
              <span>
                Topical wants to tap into the current content consumption
                culture: with every minute, a new trend is born and if you
                blink, you will miss it.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/topical_am_02.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                Topical believes that people today are a breed made from NOW –
                everything we do is now, everything we want is now. We like,
                share, tweet, repeat. This dynamic, the transitional nature of
                the medium – ever changing and constantly evolving- is reflected
                in the visual language we created for Topical. The brand
                identity is bold and young, and comes alive in the digital
                world. The motion graphics we created infuse the brand with
                energy and give the sense of keeping up with our fast-paced
                world.
              </span>
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <video
                src="/videos/topical_am_07.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/topical_sq_01.jpg"
              />
              <video
                src="/videos/topical_am_10.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/topical_sq_02.jpg"
              />
              <video
                src="/videos/topical_am_11.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/topical_sq_03.jpg"
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/topical_am_01.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_info_container">
            <div className="info_left">
              <span>
                Topical Café is another off-shoot of this project that we are
                excited about. It’s currently in the works and is an extension
                of the same brand thought. At the café, customers will be served
                a takeaway coffee along with the day’s trending news around it.
              </span>
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover padding-bottom">
              <video
                src="/videos/topical_am_04.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container padding-bottom">
            <div className="project__grid__pic-wrap">
              <LazyLoadImage
                className="project__item__img"
                alt="Kinstrukto"
                src="/images/topical_sq_04.jpg"
              />
              <video
                src="/videos/topical_am_09.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>

          <section className="project_grid_container">
            <div className="hd_cover">
              <video
                src="/videos/topical_am_05.mp4"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </section>
        </div>
      </div>

      <section className="project-nav">
        <section id="previous_project">
          <Link className="previous_project" to="/topical">
            <div className="previous-tab">
              <h3>Previous project</h3>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="details"
            >
              <motion.figure className="cards__item__pic-wrap">
                <motion.video
                  className="cards__item__img"
                  alt="STUDIO AD+AD"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src="/videos/studio_adad_am.mp4"
                  transition={transition}
                />
              </motion.figure>
              <motion.div className="cards__item__info">
                <div className="title">STUDIO AD+AD</div>
                <div className="text">Converging two perspectives into one</div>
              </motion.div>
            </motion.div>
          </Link>
        </section>

        <section id="next_project">
            <Link className="next_project" to="/tira">
              <div className="next-tab">
                <h3>Next project</h3>
              </div>
              <motion.div
                exit={{ opacity: 0 }}
                transition={transition}
                className="details"
              >
                <motion.figure className="cards__item__pic-wrap">
                  <motion.video
                    className="cards__item__img"
                    alt="Topical"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="/videos/tira_cover.mp4"
                    transition={transition}
                  />
                </motion.figure>
                <motion.div className="cards__item__info">
                  <div className="title">Tira Beauty</div>
                  <div className="text">
                  Making a playful introduction
                  </div>
                </motion.div>
              </motion.div>
            </Link>
          </section>
      </section>
    </>
  );
}
